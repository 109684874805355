import Cookies from "js-cookie";



const hasAccess = (resourseId, action) => {
  if (!resourseId || !action) {
    return false;
  }

  const adminInfo = Cookies.get("privileges");
  const resources = adminInfo ? JSON.parse(adminInfo) : [];
  const resource = resources.find((res) => res.id === resourseId);
  if (resource) {
    return resource[action] || false;
  }
  return false;
};

const getResourcePermissionsById = (resourseId) => {
  try {
    if (!resourseId) {
      return false;
    }
    const adminInfo = Cookies.get("privileges");
    const resources = adminInfo ? JSON.parse(adminInfo) : [];
    const resource = resources.find((res) => res.id === resourseId);


    return resource;
  } catch (error) {
    console.log("checkPermission error: ", error);
    return false;
  }
};

const getUserType = () => {

  const userType = Cookies.get("userType");
  return userType;
};


const permissionService = {
  getResourcePermissionsById,
  hasAccess,
  getUserType,
};

export default permissionService;