import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authHeader from "./services/auth-header";
import { useParams, useLocation } from "react-router-dom";
import apiRequest from "services/axois";
import Cookies from "js-cookie";

function Protected(props) {
  const Component = props.component;
  const permission = props.permission;
  const type = props.type;
  const navigate = useNavigate();
  const location = useLocation();
  const cookieTimeOut = 0.5; // half day
  const cookieOptions = {
    expires: cookieTimeOut,
    sameSite: "None",
    secure: true,
    // sameSite: "Lax",
    // secure: false,
  };

  let path = location.pathname;
  useEffect(() => {
    protectRoute();
  }, []);

  async function protectRoute() {
    const routeList = [];
    await apiRequest(
      "post",
      "/auth/test-token",
      {
        token: localStorage.getItem("user"),
        userId: localStorage.getItem("userId"),
      },
      { headers: authHeader() }
    )
      .then((result) => {
        Cookies.set(
          "privileges",
          JSON.stringify(result?.data?.privileges),
          cookieOptions
        );
        Cookies.set("userType", result.data.type, cookieOptions);
        if (type) {
          if (Array.isArray(type) && type.includes(result.data.type)) {
            console.log("1!type ", type, result.data.type);
            navigate("/login");
          } else if (type !== result.data.type) {
            navigate("/login");
          }
        } else {
          if (
            ![
              "Amanah",
              "Teller",
              "super admin",
              "Super_Admin",
              "manager",
              "Manager",
              "branch admin",
              "BRANCH_Admin",
              "CFO",
            ].includes(result.data.type)
          ) {
            throw new Error("not allowed to view Admin");
          }

          let isAllowed = false;
          if (!permission) {
            return;
          }

          for (let route of result?.data?.privileges) {
            for (let perm of permission) {
              if (
                (perm === "read_" + route.id && route.read) ||
                (perm === "create_" + route.id && route.create) ||
                (perm === "edit_" + route.id && route.edit)
              ) {
                isAllowed = true;
                break;
              }
            }
            if (isAllowed) {
              break;
            }
          }
          if (!isAllowed) {
            console.log("not allowed????");
            navigate("/admin/dashboard");
          }
        }
      })
      .catch((err) => {
        console.log("resss err: ", err);
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("privileges");
        localStorage.removeItem("type");
        navigate("/login");
        console.log("2!type ", type);
      });
  }

  return (
    <div>
      <Component />
    </div>
  );
}

export default Protected;
