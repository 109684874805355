import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Protected from "../../Protected.js";
import ChangePassword from "pages/Login/ChangePassword.js";

const CorporateAdmin = lazy(() => import("pages/CorporateDashboard/Admin"));
const CorporateDashboard = lazy(() =>
  import("pages/CorporateDashboard/Dashboard")
);
const BookingCorporate = lazy(() =>
  import("pages/CorporateDashboard/Booking/Booking")
);
const BookingDetails = lazy(() =>
  import("pages/CorporateDashboard/Booking/BookingDetails")
);
const MyCoursesCorporate = lazy(() =>
  import("pages/CorporateDashboard/Booking/MyCourses")
);
const CorporateDetails = lazy(() =>
  import("pages/CorporateDashboard/Courses/CorporateDetails")
);
const SchedulingCorporate = lazy(() =>
  import("pages/CorporateDashboard/Scheduling")
);
const PaymentCorporate = lazy(() =>
  import("pages/CorporateDashboard/Payments")
);

const PaymentCheckOutCorporate = lazy(() =>
  import("pages/CorporateDashboard/Payments/CheckOut")
);
const CorporateRequest = lazy(() =>
  import("pages/CorporateDashboard/Requsts/MyRequst")
);

const ViewExam = lazy(() => import("pages/Dashboard/ViewExam"));
const ViewTraning = lazy(() => import("pages/Dashboard/ViewTraning"));
const StudentsList = lazy(() =>
  import("pages/CorporateDashboard/Students/list")
);
const StudentsProfile = lazy(() => import("components/profile/Wizard"));

const CorporateScore = lazy(() =>
  import("pages/CorporateDashboard/Scores/Score.js")
);

const StudentRegisterWizard = lazy(() =>
  import("components/wizardCorporate/pages/Student.js")
);

export function CorporateRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected type={"corporate"} component={CorporateAdmin} />}
      >
        <Route
          path="dashboard"
          W
          element={
            <Protected type={"corporate"} component={CorporateDashboard} />
          }
        />

        <Route
          path="booking"
          element={
            <Protected type={"corporate"} component={BookingCorporate} />
          }
        />
        <Route
          path="booking/:id"
          element={<Protected type={"corporate"} component={BookingDetails} />}
        />
        <Route
          path="courses"
          element={
            <Protected type={"corporate"} component={MyCoursesCorporate} />
          }
        />
        <Route
          path="course/:id"
          element={
            <Protected type={"corporate"} component={CorporateDetails} />
          }
        />

        <Route
          path="scheduling"
          element={
            <Protected type={"corporate"} component={SchedulingCorporate} />
          }
        />

        <Route
          path="students"
          element={<Protected type={"corporate"} component={StudentsList} />}
        />

        <Route
          path="students/profile/:id"
          element={<Protected type={"corporate"} component={StudentsProfile} />}
        />

        <Route
          path="scores"
          element={<Protected type={"corporate"} component={CorporateScore} />}
        />

        <Route
          path="payments"
          element={
            <Protected type={"corporate"} component={PaymentCorporate} />
          }
        />
        <Route
          path="payments/:id"
          element={
            <Protected type={"corporate"} component={PaymentCorporate} />
          }
        />

        <Route
          path="payments/check-out/:id"
          element={
            <Protected
              type={"corporate"}
              component={PaymentCheckOutCorporate}
            />
          }
        />
        <Route
          path="students/add-new-student"
          element={
            <Protected type={"corporate"} component={StudentRegisterWizard} />
          }
        />

        <Route
          path="change-password"
          element={<Protected type={"corporate"} component={ChangePassword} />}
        />

        <Route
          path="requests"
          element={
            <Protected type={"corporate"} component={CorporateRequest} />
          }
        />

        <Route
          path="view-exam/:id"
          element={<Protected type={"corporate"} component={ViewExam} />}
        ></Route>
        <Route
          path="View-training/:id"
          element={<Protected type={"corporate"} component={ViewTraning} />}
        ></Route>
      </Route>
    </Routes>
  );
}
