import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";

import { AdminRoutes } from "routes/AdminRoutes";
import { StudentRoutes } from "routes/StudentRoutes";
import { InstructorRoutes } from "routes/InstructorRoutes";
import { ExaminerRoutes } from "routes/ExaminerRoutes";
import { CorporateRoutes } from "routes/CorporateRoutes";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";

import Auth from "./pages/Login/Auth";

const StudentRegisterWizard = lazy(() =>
  import("./components/wizard/pages/Student")
);
const CorporateRegisterWizard = lazy(() =>
  import("./components/wizard/pages/Corporate")
);
const MedicalRegisterWizard = lazy(() =>
  import("./components/wizard/pages/Medical")
);
const TrainingRegisterWizard = lazy(() =>
  import("./components/wizard/pages/Training")
);

// // Products
const ProductsList = lazy(() => import("./pages/Products/List"));
const ProductsDetails = lazy(() => import("./pages/Products/Details"));
const ProductPayment = lazy(() => import("./pages/Products/Payment"));

function App() {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang") || "en";
  const isRTL = i18n.language === "en" ? "ltr" : "rtl";

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <div className={isRTL ? "rtl" : "ltr"} lang={i18n.language}>
      <Toaster />
      <Suspense
        fallback={
          <div className="text-lg font-bold m-6 animate-bounce">
            {i18n.language === "en" ? "Loading..." : "جار تحميل البيانات"}
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Login />} />

            <Route path="/Auth/:token" element={<Auth />} />

            <Route
              path="/student-register"
              element={<StudentRegisterWizard />}
            />

            <Route
              path="/training-register"
              element={<TrainingRegisterWizard />}
            />
            <Route
              path="/medical-register"
              element={<MedicalRegisterWizard />}
            />
            <Route
              path="/corporate-register"
              element={<CorporateRegisterWizard />}
            />

            <Route path="/admin/*" element={<AdminRoutes />} />

            <Route path="/student/*" element={<StudentRoutes />} />

            <Route path="/instructor/*" element={<InstructorRoutes />} />

            <Route path="/examiner/*" element={<ExaminerRoutes />} />

            <Route path="/corporate/*" element={<CorporateRoutes />} />

            <Route path="products" element={<ProductsList />} />
            <Route path="product-details/:id" element={<ProductsDetails />} />
            <Route path="product-payment/:id" element={<ProductPayment />} />

            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
