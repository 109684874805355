import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "../../Protected.js";
import ChangePassword from "pages/Login/ChangePassword.js";
const ExamAdmin = lazy(() => import("pages/ExamDashboard/Admin"));
const ExaminerDashboard = lazy(() => import("pages/ExamDashboard/Dashboard"));
const AddExamInExaminer = lazy(() => import("pages/ExamDashboard/AddExam"));
const SchedulingExaminer = lazy(() => import("pages/ExamDashboard/Scheduling"));
const ExaminerScore = lazy(() => import("pages/ExamDashboard/Scores/Score"));

export function ExaminerRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected type={"Examiner"} component={ExamAdmin} />}
      >
        <Route
          path="dashboard"
          element={
            <Protected type={"Examiner"} component={ExaminerDashboard} />
          }
        />
        <Route
          path="add-exam/:id"
          element={
            <Protected type={"Examiner"} component={AddExamInExaminer} />
          }
        />

        <Route
          path="scheduling"
          element={
            <Protected type={"Examiner"} component={SchedulingExaminer} />
          }
        />
        <Route
          path="scores"
          element={<Protected type={"Examiner"} component={ExaminerScore} />}
        />
        <Route
          path="change-password"
          element={<Protected type={"Examiner"} component={ChangePassword} />}
        />
      </Route>
    </Routes>
  );
}
