import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "../../Protected.js";
import ChangePassword from "pages/Login/ChangePassword.js";

const StudentAdmin = lazy(() => import("pages/StudentDashboard/Admin"));
const StudentDashboard = lazy(() => import("pages/StudentDashboard/Dashboard"));
const ExamStudent = lazy(() => import("pages/StudentDashboard/Exams"));
const CheckOutStudent = lazy(() =>
  import("pages/StudentDashboard/Exams/CheckOut")
);
const ExamSession = lazy(() => import("pages/StudentDashboard/Exams/Session"));
const ExamReview = lazy(() => import("pages/StudentDashboard/Exams/Review"));
const ExamDone = lazy(() => import("pages/StudentDashboard/Exams/Score"));
const BookingStudent = lazy(() =>
  import("pages/StudentDashboard/Booking/Booking")
);
const MyCoursesStudent = lazy(() =>
  import("pages/StudentDashboard/Booking/MyCourses")
);
const BookingDetails = lazy(() =>
  import("pages/StudentDashboard/Booking/BookingDetails")
);

const StudentDetails = lazy(() =>
  import("pages/StudentDashboard/Courses/StudentDetails.js")
);

const SchedulingStudent = lazy(() =>
  import("pages/StudentDashboard/Scheduling")
);
const StudentScore = lazy(() => import("pages/StudentDashboard/Scores/Score"));
const PaymentStudent = lazy(() => import("pages/StudentDashboard/Payments"));
const DoneStudent = lazy(() => import("pages/StudentDashboard/Courses/Done"));
const PaymentCheckOutStudent = lazy(() =>
  import("pages/StudentDashboard/Payments/CheckOut")
);
const StudentRequest = lazy(() =>
  import("pages/StudentDashboard/Requsts/MyRequst")
);

const ViewExam = lazy(() => import("pages/Dashboard/ViewExam"));
const ViewTraning = lazy(() => import("pages/Dashboard/ViewTraning"));
const InstructorContact = lazy(() =>
  import("pages/InstructorDashboard/Contact/Contact")
);
export function StudentRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Protected type={"student"} component={StudentAdmin} />}
      >
        <Route
          path="dashboard"
          W
          element={<Protected type={"student"} component={StudentDashboard} />}
        />

        <Route
          path="booking"
          element={<Protected type={"student"} component={BookingStudent} />}
        />
        <Route
          path="courses"
          element={<Protected type={"student"} component={MyCoursesStudent} />}
        />
        <Route
          path="booking/:id"
          element={<Protected type={"student"} component={BookingDetails} />}
        />
        <Route
          path="course/:id"
          element={<Protected type={"student"} component={StudentDetails} />}
        />

        <Route
          path="scheduling"
          element={<Protected type={"student"} component={SchedulingStudent} />}
        />

        <Route
          path="exam"
          element={<Protected type={"student"} component={ExamStudent} />}
        />

        <Route
          path="exam/check-out/:id"
          element={<Protected type={"student"} component={CheckOutStudent} />}
        />
        <Route
          path="scores"
          element={<Protected type={"student"} component={StudentScore} />}
        />

        <Route
          path="payments"
          element={<Protected type={"student"} component={PaymentStudent} />}
        />
        <Route
          path="payments/:id"
          element={<Protected type={"student"} component={PaymentStudent} />}
        />

        <Route
          path="payments/check-out/:id"
          element={
            <Protected type={"student"} component={PaymentCheckOutStudent} />
          }
        />

        <Route
          path="requests"
          element={<Protected type={"student"} component={StudentRequest} />}
        />

        <Route
          path="exam/done"
          element={<Protected type={"student"} component={DoneStudent} />}
        />
        <Route
          path="exam/session/:id"
          element={<Protected type={"student"} component={ExamSession} />}
        />
        <Route
          path="exam/session-review/:id"
          element={<Protected type={"student"} component={ExamReview} />}
        />
        <Route
          path="exam/score"
          element={<Protected type={"student"} component={ExamDone} />}
        />
        <Route
          path="view-exam/:id"
          element={<Protected type={"student"} component={ViewExam} />}
        ></Route>
        <Route
          path="View-training/:id"
          element={<Protected type={"student"} component={ViewTraning} />}
        ></Route>

        <Route
          path="contacts"
          element={<Protected type={"student"} component={InstructorContact} />}
        />

        <Route
          path="change-password"
          element={<Protected type={"student"} component={ChangePassword} />}
        />
      </Route>
    </Routes>
  );
}
