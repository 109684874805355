import React, {useEffect} from 'react'
import {useParams}  from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Auth() {
  const params = useParams();
  const navigate = useNavigate();

 
  useEffect(() => {
    if(params.token){
      params.token = params.token.split('&user_');
      console.log('params.token: ',params.token)
      localStorage.setItem("user", JSON.stringify(params.token[0]))
      localStorage.setItem("userId", parseInt(params.token[1]));

      setTimeout(() => {
        navigate('/admin/dashboard');
      }, 2000);
    } else {
      navigate("/login")
    }
  })

  return (
    <div>Routing . . .</div>
  )
}

export default Auth